.Header-text {
    font-size: large;
    font-weight: bold;
    color: #37474f;
  }

.Routes-screen {
  top: 70px;
  position: relative;
  width: 100%
}
  
.Header-container {
    text-align: center;
    padding: 10px;
}

.Routes-table-body {
  width: 100%
}

.Routes-table-cell {
  width: 100%
}

.Routes-table-row {
  display: flex;
  width: 100%;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}