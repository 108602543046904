.Skipstop-header-container {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 1;
    position: sticky;
  }

.Skipstop-header-text {
  font-size: medium;
  font-weight: bold;
  align-self: center;
  line-height: 1.5em;
}

.Skipstop-header-icon {
  position: absolute;
  left: 30px
}

.Skipstop-screen {
    width: 100vw;
    height: 100%;
    z-index: 1;
    position: relative;
    top: 70px;
}

.Skipstop-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px
}

.Skipstop-title {
  font-size: large;
  font-weight: bold;
}

.Skipstop-detail-container {
  display: flex;
  flex-direction: column;
}

.Skipstop-detail-row {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.Skipstop-detail-title {
  width: 100px;
  font-size: small;
  font-weight: 500;
}

.Skipstop-detail-content {
  font-size: small;
}

.Skipstop-button-container {
    background-color: #fff;
    border-radius: 40px;
    border-color: red;
    border-width: 1px;
    border-style: solid;
    padding: 20px 10px 20px 10px;
    margin-top: 70px; 
    text-align: center;
}

.photoModeIcon-single {
  font-size: larger!important;
}