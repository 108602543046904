.Stoplist-header-text {
    font-size: medium;
    color: #37474f;
    font-weight: 500;
}
  
.Stoplist-header-container {
    text-align: center;
    padding: 15px;
    display: inline-flex;
    justify-content: space-between;
    background-color: #fff;
    z-index: 50;
    position: fixed;
    top: 70px;
    width: 100%;
}

.Stoplist-leftside-header {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.Stoplist-screen {
    top: 120px;
    position: relative;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1;
    z-index: 1;
    overflow-x: hidden;
    background-color: #3B4252;
}

.no-valid-stops {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -30px;
    width: 100px;
    height: 100px;
    font-size: x-large;
}

.Stoplist-screen::-webkit-scrollbar {display:none;}

.Backbutton-container {
    position: absolute;
    left: 30px;
    padding-right: 10px;
}

.Stoplist-inner-container {
    background-color: #3B4252;
    width: -webkit-fill-available;
    width: 120%;
    margin-left: -50px;
    height: 100%;
    padding-left: 0px;
    padding-top: 10px
}

.Stoplist-inner-active-container {
    background-color: #3B4252;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* .Stoplist-inner-container::after {
    content: ' ';
    position: absolute;
    width: 6px;
    background-color: #37474f;
    left: 49px;
    height: 50%;
    top: 30%;
} */
  