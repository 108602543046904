.Stopcard-box-inactive {
    width: 80%;
    height: 200px;
    background-color: #ffffffe3;
    box-shadow: 0 4px 8px 5px rgba(0, 0, 0, 0.2), 0 10px 20px 0px rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    opacity: 1;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    align-self: center;
    display: flex;
    z-index: 0;
    padding-left: 30px;
}

.Stopcard-box-inactive-small {
    width: 80%;
    height: 100px;
    background-color: #ffffffe3;
    box-shadow: 0 4px 8px 5px rgba(0, 0, 0, 0.2), 0 10px 20px 0px rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    opacity: 1;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    align-self: center;
    display: flex;
    z-index: 0;
    padding-left: 30px;
}

.Stopcard-box-active {
    width: 80%;
    height: 210px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 15px 10px rgba(0, 0, 0, 0.2), 0 0px 20px 0px rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    padding-top: 10px;
    padding-left: 80px;
    padding-right: 50px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    align-self: center;
    display: flex;
    z-index: 0;
    opacity: 1;
}

.Stopcard-box-finished {
    width: 80%;
    height: 120px;
    border-radius: 5px;
    padding-top: 10px;
    padding-left: 90px;
    padding-right: 50px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    align-self: center;
    display: flex;
    z-index: 0!important;
}

.stopcard-alert {
    position: absolute;
    top: 0;
    width: auto;
    z-index: 1000;
}

.Stopcard-box-active-left {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 0px;
    width: 100%;
    height: 100%;
    max-height: -webkit-fill-available;
    justify-content: space-evenly;
    padding-left: 20px;
    padding-right: 10px;
    margin-right: 10px;
}

.Route-icon {
    position: relative;
    z-index: 0;
    margin-top: -3px;
}

.Stopcard-timeline-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Stopcard-timeline-box-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    right: 7%;
    left: -7%;
}

.Stopcard-inactive-content-box {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-top: 20px;
}


.Stopcard-row-container {
    display: flex;
    justify-content: space-between;
}

.Stopcard-buttons-one {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.Stopcard-line {
    position: relative;
    z-index: 0;
    width: 10px;
    background-color : #e4e3e3;
    height: 100px;
    border-radius: 3px;
    margin-top: -3px;
    padding-bottom: 130px;
    box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.2), 0 10px 20px 0px rgba(0, 0, 0, 0.19);
}

.Stopcard-line-active {
    position: relative;
    z-index: 0;
    width: 10px;
    background-color : #e4e3e3;
    min-height: 100%; 
    border-radius: 3px;
    margin-top: -3px;
    padding-bottom: 25px;
    box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.2), 0 10px 20px 0px rgba(0, 0, 0, 0.19);
}

.Stopcard-line-inactive {
    position: relative;
    z-index: 0;
    width: 10px;
    background-color : #747c80;
    height: 100px;
    border-radius: 3px;
    margin-top: -5px;
    padding-bottom: 140px;
    box-shadow: 0 4px 8px 5px rgba(0, 0, 0, 0.2), 0 10px 20px 0px rgba(0, 0, 0, 0.19);
}

.Stopcard-line-invisible {
    position: relative;
    z-index: 0;
    width: 10px;
    background-color : #37474f;
    height: 0px;
    margin-left: -22px;
    margin-top: 0px;
    border-radius: 3px;
    margin-bottom: -10px;
}

.Stopcard-buttons-two {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    position: relative;
    bottom: 0px;
}

.Stopcard-third-row-text {
    font-size: small;
}

.Stopcard-header-text {
    font-size: large;
    font-weight: bold;
}

.Stopcard-timewindow-box {
    border-radius: 10px;
    border-width: 0.5px;

}

.Stopcard-header-text-inactive {
    font-size: large;
    font-weight: bold;
    color: #fff;
}

.Stopcard-moreinfo-container {
    padding: 10px 10px 10px 0px;
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(255, 255, 255, 0);
    border-style: solid;
    display: flex;
    justify-content: flex-start;
    width: auto
}

.Stopcard-moreinfo-text {
    color: #2196f3;
    align-self: center;
    padding-left: 5px;
    font-size: 11px;
}

.Stopcard-moreinfo-text-inactive {
    color: #fff;
    align-self: center;
    padding-left: 5px;
    font-size: smaller;
}

.Stopcard-inactive-header {
    font-size: large;
    font-weight: bold;
    color: black;
}
.Stopcard-inactive-address {
    font-size: small;
    color: rgb(84, 110, 122);
}

.Stopcard-directions-container {
    border-radius: 5px;
    color: #2196f3;
    border-color: #2196f3;
    background-color: #e3f2fd;
    border-width: 0.5px;
    padding: 10px;
    border-style: solid;
    display: flex;
}

.Stopcard-directions-text {
    color: #2196f3;
    align-self: center;
    padding-left: 5px;
    font-size: smaller;
}

.Stopcard-timewindow-text {
    border-color: #fff;
    border-width: 2px;
    border-radius: 10px;
    font-size: small;
}

.Stopcard-address-text-inactive {
    font-size: small;
    color: #fff;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    max-width: 200px;
}

.Stopcard-action-holder {
    max-width: 100%;
}

.Stopcard-address-text {
    font-size: small;
    white-space: pre;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    max-width: 180px;
}

.Stopcard-button-text {
    font-size: medium;
    font-weight: bold;
}

.Stopcard-button-arrive {
    background-color: #42a5f5;
    width: 80px;
    height: 40px;
    border-radius: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: #42a5f5;
    color: #fff;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

}

.Stopcard-button-skip {
    border-color: #ef5350;
    background-color: #fff;
    border-width: 1px;
    width: 80px;
    height: 40px;
    border-radius: 40px;
    border-style: solid;
    color: #ef5350;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Stopcard-hr {
    border-top: 0.2px solid rgb(216, 216, 216);
    margin-right: 7px;
    width: 100%;
}

.Stopcard-ETA-box {
    border-radius:  40px;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2), 0 10px 20px 0px rgba(0, 0, 0, 0.19);
    background-color:#2c8dfb;
    border-color: #2c8dfb;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: x-small;
    align-self: center;
    color: #fff;
    position: relative;
    z-index: 1;
    margin-top: -3px;
}

.link {
    text-decoration: none;
    display: flex;
    justify-content: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Completed-route {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 20px;
    margin-left: 40px;
    margin-bottom: 0;
    margin-top: 0;
    overflow: hidden;
}

.Incomplete-route {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0;
    margin-top: 0;
    overflow: hidden;
    background-color: #fff;
}

.Next-route {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 20px;
    margin-left: 40px;
    margin-bottom: 0;
    margin-top: 0;
    overflow: hidden;
    background-color: #fff;
    opacity: 0.5;
}

.Route-inner-container {
    display: flex;
    flex-direction: column;
    max-height: 60px;
    margin: 0;
    padding-left: 15%;
}

.Route-text {
    color: white;
    line-clamp: 1;
}

.Route-line {
    position: relative;
    z-index: 0;
    width: 10px;
    background-color : #37474f;
    height: 80px;
    margin-left: -22px;
    margin-top: 0px;
    border-radius: 3px;
    margin-bottom: -10px;
}

.Route-line-invisible {
    position: relative;
    z-index: 0;
    width: 10px;
    background-color : #37474f;
    height: 0px;
    margin-left: -22px;
    margin-top: 0px;
    border-radius: 3px;
    margin-bottom: -10px;
}

.Route-icon {
    position: relative;
    z-index: 0;
    margin-top: -3px;
}

.Loading-icon {
    position: relative;
    z-index: 2;
    margin-top: -3px;
    background-color: #fff;
    border-radius: 100px;
}

.Route-icon-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0
}

.Stopcard-icon {
    opacity: 3.0; 
    z-index: 1; 
    position: relative;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}

/* .Route-icon::after {
    content: ' ';
    position: fixed;
    width: 6px;
    background-color: #37474f;
    left: 49px;
    top: 150px;
    height: 20%;
    
} */