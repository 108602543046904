.Moreinfo-header-container {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: -1;
    position: sticky;
    width: 100%;
  }

.Moreinfo-header-text {
  font-size: medium;
  font-weight: bold;
  align-self: center;
  line-height: 1.5em;
  margin-left: -20px
}

.Moreinfo-header-icon {
  position: absolute;
  left: 30px
}

.Moreinfo-screen {
    top: 70px;
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: 100%;
    z-index: 1;
}

.Moreinfo-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px
}

.Moreinfo-title {
  font-size: large;
  font-weight: bold;
}

.Moreinfo-detail-container {
  display: flex;
  flex-direction: column;
}

.Moreinfo-detail-row {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.Moreinfo-detail-title {
  width: 100px;
  min-width: 100px;
  font-size: small;
  font-weight: 500;
}

.Moreinfo-detail-content {
  font-size: small;
  white-space: normal;
  text-overflow: ellipsis;
  max-width: 300px;
  position: sticky;
}

.Moreinfo-communications-buttons {
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  bottom: 50px;
  left: 10px;
  width: 100%;
  align-items: center;
}

.Moreinfo-button {
  display: flex;
  flex-direction: row;
  background-color: skyblue;
  border-radius: 20px;
  width: 100px;
  height: 50px;
  justify-content: center;
  align-content: center;
  text-decoration: none;
  align-items: center;
  color: #000;
  font-weight: bold;
  line-height: 1.5em;
}

.Moreinfo-icon {
  align-self: center;
  padding-right: 10px;
}