.Completestop-header-container {
    padding: 20px;
    position: sticky;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 1;
  }

.Completestop-header-text {
  font-size: large;
  font-weight: bold;
  align-self: center;
  line-height: 1.5em;
}

.Completestop-header-icon {
  position: absolute;
  left: 30px
}

.camera{
  /* padding: 0px;
  z-index: 999;
  min-height:100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  width: -moz-available;          
  width: -webkit-fill-available; 
  width: fill-available;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  -webkit-overflow-scrolling: touch; */
  display: block;
  position: relative;
  max-height: 100vh;
  width: 100%;
  z-index: 999;
  background-color: #fff;
}

.photo-icon-container{
  position: absolute;
  top: 0;
  z-index: -1;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height:100vh;
  background-color: #fff;
  padding-top: 70px;
  min-height: -webkit-fill-available;
  width: 100vw;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.signature{
  padding: 0px;
  z-index: 999;
  position: relative;
  background-color: #fff;
  width: 100vw;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  min-height:100vh;
  min-height: -webkit-fill-available;
  border-color: black;
  border-width: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;

}

.Completestop-screen {
    max-height:100vh;
    max-height: -webkit-fill-available;
    width: 100vw;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    z-index: 1;
    position: relative;
    top: 70px;
}

.Completestop-content-container {
    display: block;
    max-height: 100vh;
    width: 100%;
}

.Completestop-title {
    font-size: large;
    font-weight: bold;
}

.Completestop-detail-container {
    display: flex;
    padding: 20px;
    flex-direction: column;
}

.Completestop-detail-row {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.Completestop-detail-title {
  width: 100px;
  font-size: small;
  font-weight: 500;
}

.Completestop-detail-content {
  font-size: small;
}

.Completestop-button-container {
    background-color: #fff;
    border-radius: 40px;
    border-color: lightskyblue;
    background-color: lightskyblue;
    border-width: 1px;
    border-style: solid;
    padding: 20px 10px 20px 10px;
    margin-top: 70px;
    text-align: center;
    font-weight: bold;
    z-index: 1000;
    position: relative;
}

.Completestop-button-container-finish {
  background-color: #fff;
  border-radius: 40px;
  border-color: lightskyblue;
  background-color: lightskyblue;
  border-width: 1px;
  border-style: solid;
  padding: 20px 10px 20px 10px;
  text-align: center;
  font-weight: bold;
  align-self: center;
  width: 280px;
  z-index: 1000;
  position: absolute;
  bottom: 10px
}

.Completestop-button-container-finish-inactive {
  background-color: #fff;
  color: gray;
  border-radius: 40px;
  border-color: #dbdada;
  background-color: #dbdada;
  border-width: 1px;
  border-style: solid;
  padding: 20px 10px 20px 10px;
  text-align: center;
  font-weight: bold;
  align-self: center;
  width: 280px;
  z-index: 1000;
  position: absolute;
  bottom: 10px
}

.clearicon {
  position: absolute;
  right: 10px;
  top: 20px;
  font-size: x-large;
}

.companyinfo {
  margin-top: 10px;
  align-self: center;
  font-weight: bold;
}

.photocontainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;

}

.photo-preview-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 60px;
  z-index: 1009;
  width: -webkit-fill-available;

}

.sigpreview{
 position: relative;
top: 20px
}

.goback-button {
  display: flex;
}

.photopreview-image{
  height: 300px;
  width: 300px;
  align-self: center;
  width: -webkit-fill-available;
  max-height: 50%;
}

.goback {
  display:flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  font-weight: bold;
  box-sizing: border-box;
  line-height: 1.5em;
  padding: 20px;
  width: 100%;
  margin-bottom: 10px;
}

.backicon {
  position: relative;
  z-index: 1001;
}

.photoModeIcon {
  padding: 5px;
  width: 150px;
  margin-bottom: 0px;
  background-color: #dbdada;
  text-align: center;
  border-radius: 20px;
  font-weight: bold;
  font-size: xxx-large;
}

.photo-input-invisible {
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: -1;
  width: 0px;
  height: 0px;
}

.photo-input {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #dbdada;
  padding: 15px;
  width: 50%;
  text-align: center;
  align-self: center;
  border-radius: 10px;
  font-weight: bold;
  font-size: medium;
}

.upload-button {
  background-color: lightblue;
  font-family: Arial, Helvetica, sans-serif;
  border-style: none;
  padding: 15px;
  width: 60%;
  align-self: center;
  border-radius: 10px;
  font-weight: bold;
  font-size: medium;
}

.gallery-screen {
  background-color: #fff;
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.photoModeIcon-text {
  font-size: x-large;
}

.photopreview{
  display: block;
  margin: 10px;
  align-self: center;
  max-width: 100%;
  max-height: 70%;
}

.canvas {
  border-width: 5px;
  border-color: #dbdada;
  border-style: solid;
  position: relative;
  z-index: 1000;
}

.Completestop-button-upper-container {
    display: flex;
    flex-direction: column;
}

.signaturehere {
    position: absolute;
    top: 350px;
    left: 50%;
    margin-top: -130px;
    margin-left: -50px;
    width: 100%;
    height: 100px;
    z-index: 1002;
    color: #dbdada
}

.Completestop-button {
    border-radius: 20px;
    color: rgb(48, 45, 45);
    background-color: #dbdada;
    padding: 10px;
    margin: 10px;
    text-align: center;
}