#map {
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    overflow: hidden;
    z-index: 25;
}

.map {
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    overflow: hidden;
    z-index: 25;
}


.Map-screen {
    overflow: hidden;
    top: 120px;
    position: fixed;
    min-height:100vh;
    min-height: -webkit-fill-available;
    width: 100vw;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
}

.Map-swiper-container {
    height: 0px;
}

.map-alert {
    position: absolute;
    z-index: 200;
    width: 100%;
    color: #000
}

.Map-header-container {
    text-align: center;
    padding: 15px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
}

.Map-mylocation {
    position: absolute;
    z-index: 500;
    top: 20px;
    right: 20px;
}

.fancy-icon {
    position: absolute;
    left: 4px;
    top: 5px;
    
}

.fancy-icon-active {
    position: absolute;
    left: 4px;
    top: 5px;
    z-index: 1001!important;
}


.fancy-icon-number {
    position: absolute;
    top: 2px;
    left: 3px;
    border-radius: 20px;
    /* font-size: medium; */
    border-color: black;
    border-width: 2px;
    color: #3B4252;
    z-index: 560;
    background-color: #fff;
    width: 21px;
    height: 21px;
    font-weight: 900;
    /* padding-left: 0px;
    margin-left: 3px;
    margin-top: 2px; */
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
    /* font-weight: bold;
    text-align: -webkit-center; */
}

.fancy-icon-inner-container {
    position: relative;
}

.fancy-icon-inner-icon-active{
    position: absolute;
    top: 0;
    left: 0;
    color:rgb(92, 130, 190);
    /* z-index: 1001!important; */
}

.fancy-icon-inner-icon{
    position: absolute;
    top: 0;
    left: 0;
    color: rgb(47, 67, 99);
}

.fancy-icon-inner-icon-inactive {
    position: absolute;
    top: 0;
    left: 0;
    color: rgb(26, 143, 221);
}

.Mapcard_container {
    position: relative;
    bottom: 220px;
    left: -100px;
    width: 100%;
    height: auto;
    z-index: 999;
    display: flex;
    align-items: center;
    
    /* transition: transform .6s cubic-bezier(0.76,-.29,.22,1.36); */
}

.Mapcard_container .span {
    padding-left: 20
}

.Map-leftside-header {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.Map-header-text {
    font-size: small;
    color: #37474f;
    font-weight: 500;
}

.marker-focused {
    z-index: 330;
}

.marker-unfocused {
    z-index: 329;
}

